<template>
  <div class="import-success">
    <el-card class="flex-column-all-center">
    <div class="iconfont icongou"></div>
   <div class="title">导入成功</div>
   <div class="throungh">{{ type == 1? '成员' : '外部联系人' }}通过手机号(邮箱)即可登录视频工作台。</div>
   <el-button  type="primary" @click="success">完成</el-button>
    </el-card>
  </div>
</template>
<script>
import request from "@request/api/index";
export default {
  components: {},
  props: {},
  watch: {},
    data() {
    return {
      type: 1
    };
  },
  created() {
    this.type=this.$route.query.type
  },
  methods: {
     success(){
       if(this.type==1){
        this.$router.replace('organization/membersDepartments');
       }else {
        this.$router.replace('organization/externalContacts');
       }
      }
  },
};
</script>
<style lang="scss" scoped>
.import-success{
    padding-top:30px;
    text-align: center;

.el-card {
    height: 780px;
    margin:0 auto;
}
.icongou{
    font-size: 52px;
    color:#52c41a;
    text-align:center;
}
.title{
    padding-top:10px;
    font-size: 24px;
    font-weight: 650;
    line-height: 32px;
    text-align:center;
}
.throungh{
    padding:20px 0;
}
}
</style>
